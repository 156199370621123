import { Injectable, Inject, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { PlatformService } from '@core/services';
import { RESPONSE } from '../../../express.tokens';
import { Observable } from 'rxjs';

/**
 * NOTE(!)
 * Логика использования BrowserGuard должна повторять правила, описанные в robots.txt
 * Что запрещено в robots.txt должно быть также закрыто с использованием BrowserGuard
 */

@Injectable()
export class BrowserGuard {
  constructor(private platform: PlatformService, @Optional() @Inject(RESPONSE) private response: any) {}

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const url = '/' + segments.join('/');
    // logger.debug('BrowserGuard:canLoad ?', url);
    return this.condition();
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // logger.debug('BrowserGuard:canLoad ?', state.url);
    return this.condition();
  }

  public canActivate(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // logger.debug('BrowserGuard:canActivate ?', state.url);
    return this.condition();
  }

  condition(): boolean {
    if (this.platform.isBrowser) {
      return true;
    } else {
      if (this.response) {
        this.response.status(403);
        this.response.statusMessage = 'Access denied';
      }
    }
    return false;
  }
}
