import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/';

@Injectable()
export class GuestGuard {
  constructor(private router: Router, private auth: AuthService) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true; // this.condition(this.auth.isAuthenticated());
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.condition(this.auth.isAuthenticated);
  }

  canActivate(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.condition(this.auth.isAuthenticated);
  }

  condition(isAuthenticated: boolean): boolean {
    if (isAuthenticated) {
      this.router.navigate([!!this.auth.backUrl ? this.auth.backUrl : '/']).then(() => {
        this.auth.backUrl = '';
      });
    }
    return !isAuthenticated;
  }
}
