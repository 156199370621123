import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { DestroyableComponent } from '@models/destroyable.component';
import { RESPONSE } from '../../../express.tokens';
import * as Sentry from '@sentry/browser';
import { User } from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService, PlatformService } from '../services/';

@Injectable()
export class AuthGuard extends DestroyableComponent {
  private me$: Observable<User>;

  constructor(
    private router: Router,
    private authService: AuthService,
    @Optional() @Inject(RESPONSE) private _response: any,
    protected readonly platform: PlatformService,
  ) {
    super(platform);
    this.me$ = this.authService.user$;
  }

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const url = '/' + segments.join('/');
    // logger.debug('AuthGuard:canLoad ?', url);
    return this.condition(url);
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // logger.debug('AuthGuard:canLoad ?', state.url);
    return this.condition(state.url);
  }

  public canActivate(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // logger.debug('AuthGuard:canActivate ?', state.url);
    return this.condition(state.url);
  }

  protected condition(backUrl: string): Observable<boolean> | boolean {
    return this.me$.pipe(
      map((me) => {
        if (!me) {
          Sentry.setUser(null);
        } else {
          Sentry.setUser({
            id: me.id,
            username: me.first_name + ' ' + me.last_name,
            segment: me.purl,
          });
        }
        const allow = this.authService.isAuthenticated;
        // logger.debug(
        //   'AuthGuard:condition',
        //   allow ? `allow (go route ${backUrl})` : `reject (go auth, then route ${backUrl})`,
        // );
        if (!allow) {
          if (this.platform.isBrowser) {
            this.authService.backUrl = backUrl;
            this.router.navigate(['/auth']).then(() => {
              // TODO: If Notification (toast) service is present we can show warning message
            });
          } else {
            this._response.statusCode = 403;
            this._response.statusMessage = 'Access denied';
          }
        }
        return allow;
      }),
    );
  }
}
