import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { logger } from '@app/core/helpers/logger';
import { EContentPanelRoute, Language, User, UserStatusEnum } from 'lingo2-models';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService, LanguageService } from '../services/';

@Injectable({
  providedIn: 'root',
})
export class RootGuard {
  constructor(private router: Router, private auth: AuthService, private languageService: LanguageService) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(state);
  }

  canActivate(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(state);
  }

  private check(state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([this.auth.user$, this.languageService.language$]).pipe(
      // takeUntil(this.ngUnsubscribe),
      map(([me, language]) => this.checkIfRootRedirect(me, language, state)),
    );
  }

  private checkIfRootRedirect(me: User, language: Language, state: RouterStateSnapshot): boolean {
    const languageAutoChangeUrls = ['/'];

    const url = state.url;
    const storedLanguage = this.languageService.storedLanguage;

    // 1. если я ранее выбирал язык
    //    И он не совпадает с текущим языком
    //    И текущий URL входит в список 'языкового редиректа'
    //      -> то отправить меня на другой языковой ресурс
    if (storedLanguage && language.code !== storedLanguage.code) {
      if (languageAutoChangeUrls.includes(url)) {
        logger.log('RootGuard', `user stored language is ${storedLanguage.code}, follow to host ${storedLanguage.url}`);
        this.languageService.redirectToHost(storedLanguage.url);
        return false;
      }
    }

    // 2. если я не гость и я на корневой странице
    //     -> то отправить меня на главную страницу
    if (me && me.status !== UserStatusEnum.guest && url && url.split('?')[0] === '/') {
      const homeUrl = EContentPanelRoute.main;
      logger.log('RootGuard', `user is signed, follow from '/' to '/${homeUrl}'`);
      this.router.navigateByUrl(`/${homeUrl}`);
      return false;
    }

    logger.log('RootGuard', 'check -> ok');
    return true;
  }
}
