import { Injectable } from '@angular/core';
import { FeaturesService, PlatformService } from '@core/services';
import { TenantEnum } from 'lingo2-models';
import { Observable } from 'rxjs';

const OVERRIDE_TENANT_PARAM = 'override-tenant';

/**
 * @example ?override-tenant=
 * @example ?override-tenant=onclass_com
 * @example ?override-tenant=onclass_me
 */

@Injectable({
  providedIn: 'root',
})
export class TenantOverrideGuard {
  constructor(private features: FeaturesService, private platform: PlatformService) {}

  canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }

  private check(): boolean {
    if (this.platform.isBrowser) {
      const query = new URLSearchParams(window.location.search);
      this.applyTenantOverrideByQuery(query);
    }
    return true;
  }

  protected applyTenantOverrideByQuery(query: URLSearchParams) {
    console.log('TenantOverrideGuard ?' + OVERRIDE_TENANT_PARAM, query, query.get(OVERRIDE_TENANT_PARAM));
    if (query.has(OVERRIDE_TENANT_PARAM)) {
      const tenant = query.get(OVERRIDE_TENANT_PARAM) as TenantEnum;
      this.features.overrideTenant(TenantEnum[tenant] ? tenant : null);
    }
  }
}
